// src/view/Menu.jsx
import React, { useState, useEffect } from 'react';
import { loadFromLocalStorage } from '../cache';

const MenuNav = () => {
    const [user, setUser] = useState(null);
    
    useEffect(() => {
        checkUser();
    }, []);

    const checkUser = async () => {
        try {
            const userData = await loadFromLocalStorage('currentUser');
            setUser(userData);
        } catch {
            setUser(null);  // No user is signed in
        }
    };

    return (
        <div>
            <div className="row navbar-expand-sm navbar-light alert-primary" style={{ borderRadius: '15px' }} >
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive2" aria-controls="navbarResponsive2" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive2">
                    <ul className="navbar-nav ms-auto py-4 py-sm-0">
                        <li className="nav-item"><a className="nav-link px-sm-3 py-3 py-sm-4 alert-link" href="/home">Home</a></li>
                        <li className="nav-item"><a className="nav-link px-sm-3 py-3 py-sm-4 alert-link" href="/media">Media</a></li>
                        <li className="nav-item"><a className="nav-link px-sm-3 py-3 py-sm-4 alert-link" href="/contact">Contact</a></li>
                        <li className="nav-item dropdown">
                            <a className="nav-link px-sm-3 py-3 py-sm-4 alert-link dropdown-toggle"  id="contactDrpDwn" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Membership
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="contactDrpDwn">
                                <li><a className="dropdown-item px-sm-3 py-3 py-sm-4 alert-link" href="/join">Register to join</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item px-sm-3 py-3 py-sm-4 alert-link" href="https://www.paypal.com/donate/?hosted_button_id=BEZCDBJ3DQ2AG">Pay membership fee</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link px-sm-3 py-3 py-sm-4 alert-link dropdown-toggle"  id="supportDrpDwn" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Support
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="supportDrpDwn">
                                <li><a className="dropdown-item px-sm-3 py-3 py-sm-4 alert-link" href="/support">Donate</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item px-sm-3 py-3 py-sm-4 alert-link" href="/shop">Shop</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link px-sm-3 py-3 py-sm-4 alert-link dropdown-toggle"  id="symposiumDrpDwn" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Symposium
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="supportDrpDwn">
                                <li><a className="dropdown-item px-sm-3 py-3 py-sm-4 alert-link" href="/youth-symposium">Grand Youth Symposium</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item px-sm-3 py-3 py-sm-4 alert-link" href="/regime-change-in-eritrea">Regime-change in Eritrea!</a></li>
                            </ul>
                        </li>
                        {/*  <li className="nav-item"><a className="nav-link px-sm-3 py-3 py-sm-4 alert-link" href="/regime-change-in-eritrea.html">Symposium</a></li> */}
                        <li className="nav-item py-sm-3">
                            <form action="https://www.paypal.com/donate" method="post" target="_top">
                                <input type="hidden" name="hosted_button_id" value="N82NQPFWU3JUS" />
                                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                            </form>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link px-sm-3 py-3 py-sm-2 alert-link" href="/shop">Shop</a>
                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_self" className="nav-item py-sm-2">
                                <input type="hidden" name="cmd" value="_cart" />
                                <input type="hidden" name="business" value="BNVQX3FD4AHRW" />
                                <input type="hidden" name="display" value="1" />
                                <input type="hidden" name="shopping_url" value="https://felsiselam.org/shop.html" />
                                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_viewcart_LG.gif" name="submit" alt="PayPal - The safer, easier way to pay online!" />
                            </form>
                        </li>
                        { user ? ( 
                            <li className="nav-item"><a className="nav-link px-sm-3 py-3 py-sm-4 alert-link" href="/dashboard">Dashboard</a></li>
                        ) : (
                            <></>
                        )}
                    </ul>
                </div>
            </div>
            <hr className="my-4" />
        </div>
    );
};
export default MenuNav;