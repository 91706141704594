// src/view/protected/MembersTable.jsx
import React, { useState, useEffect } from 'react';
import { Table, Button, Card } from "react-bootstrap";
import EditRoleModal from './EditRoleModal';
import AssignTaskModal from './AssignTaskModal'; // Import the AssignTaskModal component
import { getCurrentAuthenticatedUser, getMemberData } from './../../api/api'; // Ensure this is the correct import path

function MembersTable({ members, refreshMembers, availableGroups }) {
  const [selectedMember, setSelectedMember] = useState(null);
  const [showEditRoleModal, setShowEditRoleModal] = useState(false);
  const [showAssignTaskModal, setShowAssignTaskModal] = useState(false);
  const [expandedMemberId, setExpandedMemberId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getCurrentAuthenticatedUser();
      const currentUser = await getMemberData(user.username);
      setCurrentUser(currentUser);
    };
    fetchUser();
  }, []);

  const handleEditRole = (member) => {
    setSelectedMember(member);
    setShowEditRoleModal(true);
  };

  const handleAssignTask = (member) => {
    setSelectedMember(member);
    setShowAssignTaskModal(true);
  };

  const handleCloseModal = () => {
    setShowEditRoleModal(false);
    setShowAssignTaskModal(false);
    setSelectedMember(null);
  };

  const toggleExpand = (memberId) => {
    setExpandedMemberId(expandedMemberId === memberId ? null : memberId);
  };

  return (
    <div>
      {/* Desktop view */}
      <div className="d-none d-md-block">
        <div className="table-responsive">
          <Table bordered>
            <thead>
              <tr>
                <th>ProfilePic</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Gender</th>
                <th>Country</th>
                <th>City</th>
                <th>Profession</th>
                <th>Membership Status</th>
                <th>Role</th>
                <th>Task</th>
              </tr>
            </thead>
            <tbody>
              {members.map((member) => (
                <tr key={member.id}>
                  <td>
                    <img
                      src={member.profilePic}
                      alt="ProfilePic"
                      style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                    />
                  </td>
                  <td>{member.firstName}</td>
                  <td>{member.lastName}</td>
                  <td>{member.email}</td>
                  <td>{member.phoneNumber}</td>
                  <td>{member.gender}</td>
                  <td>{member.country}</td>
                  <td>{member.city}</td>
                  <td>{member.profession}</td>
                  <td>{member.membershipStatus}</td>
                  <td>
                    <Button variant="link" onClick={() => handleEditRole(member)}>
                      Edit Role
                    </Button>
                  </td>
                  <td>
                    <Button variant="link" onClick={() => handleAssignTask(member)}>
                      Assign Task
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* Mobile view */}
      <div className="d-block d-md-none">
        {members.map((member) => (
          <Card key={member.id} className="mb-3">
            <Card.Header onClick={() => toggleExpand(member.id)} style={{ cursor: 'pointer' }}>
              <div className="d-flex align-items-center">
                <img
                  src={member.profilePic}
                  alt="ProfilePic"
                  style={{ width: "50px", height: "50px", borderRadius: "50%", marginRight: '10px' }}
                />
                <div>
                  {member.firstName} {member.lastName} - {member.country}
                </div>
              </div>
            </Card.Header>
            {expandedMemberId === member.id && (
              <Card.Body>
                <p>Email: {member.email}</p>
                <p>Phone: {member.phoneNumber}</p>
                <p>Gender: {member.gender}</p>
                <p>City: {member.city}</p>
                <p>Profession: {member.profession}</p>
                <p>Membership Status: {member.membershipStatus}</p>
                <Button variant="link" onClick={() => handleEditRole(member)}>Edit Role</Button>
                <Button variant="link" onClick={() => handleAssignTask(member)}>Assign Task</Button>
              </Card.Body>
            )}
          </Card>
        ))}
      </div>

      {selectedMember && (
        <>
          <EditRoleModal
            show={showEditRoleModal}
            handleClose={handleCloseModal}
            member={selectedMember}
            refreshMembers={refreshMembers}
            availableGroups={availableGroups}
          />

          <AssignTaskModal
            show={showAssignTaskModal}
            handleClose={handleCloseModal}
            member={selectedMember}
            refreshMembers={refreshMembers}
            currentUser={currentUser}
          />
        </>
      )}
    </div>
  );
}

export default MembersTable;