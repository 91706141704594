import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './view/Home';
import Dashboard from './view/protected/Dashboard';
import Media from './view/Media';
import Contact from './view/Contact';
import Join from './view/Join';
import Support from './view/Support';
import Shop from './view/Shop';
import RegimeChangeSymbosium from './view/RegimeChangeSymbosium';
import YouthSymbosium from './view/YouthSymbosium';
import Nav from './view/Nav';
import PrivacyPolicy from './view/PrivacyPolicy';
import TermsAndConditions from './view/TermsAndConditions';

function App() {
  const location = useLocation();

  return (
    <div>
      {location.pathname !== "/dashboard" && <Nav />}
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/media" element={<Media />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/join" element={<Join />} />
        <Route path="/support" element={<Support />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop.html" element={<Shop />} />
        <Route path="/regime-change-in-eritrea" element={<RegimeChangeSymbosium />} />
        <Route path="/youth-symposium" element={<YouthSymbosium />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}